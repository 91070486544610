<template>
  <v-container v-if="upperLogo" fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
      >
        <v-row class="mt-12">
          <v-flex xs12>
            <v-img
              class="mx-auto"
              :src="upperLogo"
              width="100%"
              max-width="200px"
            ></v-img>
          </v-flex>
        </v-row>
        <v-row>
          <p
            class="font-weight-black text-center fx-20 mx-auto"
            style="font-size:20px; margin-top:12px;"
          >
            {{ $t('systemName') }}
          </p>
        </v-row>
        <v-row class="mt-12">
          <v-flex xs12>
            <v-img
              class="mx-auto"
              src="@/assets/img/top.gif"
              width="70vw"
              max-width="350px"
            ></v-img>
          </v-flex>
        </v-row>
        <v-row class="mt-2 ml-2 mr-2">
          <v-flex xs12>
            <v-img
              class="mx-auto"
              src="@/assets/img/copylight.gif"
              width="30vw"
              max-width="100px"
            ></v-img>
          </v-flex>
        </v-row>
        <v-row v-if="downerLogo" class="mt-4">
          <v-img
            :contain="true"
            max-height="300px"
            width="100%"
            max-width="200px"
            :src="downerLogo"
            class="mx-auto"
          />
        </v-row>
        <p class="mt-4">{{ message }}</p>
        <v-row class="my-12">
          <v-btn
            class="font-weight-black mx-auto"
            align="center"
            href="/login"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            rounded
            >{{ $t('button.start') }}</v-btn
          >
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getLogo, getMessage } from '@/apis/contractor/agencies';
import mainLogo from '@/assets/img/logo.gif';

export default {
  name: 'Home',
  components: {},
  data() {
    return { 
      message: '',
      upperLogo: '',
      downerLogo: '',
    };
  },
  methods: {
    ...mapGetters('contract', ['urlToken']),
    ...mapActions('contract', ['crearContract', 'setContractUrlToken']),
    ...mapActions('contractor', [
      'clearAccessToken',
      'clearRefreshTokenn',
      'setUrlToken',
    ]),
  },
  async created() {
    const urlToken = this.$route.query.token;

    // トークン更新
    if (urlToken) {
      if (urlToken != this.urlToken()) {
        this.crearContract();
        this.setContractUrlToken(urlToken);
        this.clearRefreshTokenn();
      }
      this.setUrlToken(urlToken);
    }
    this.clearAccessToken();
    const messageResponse = await getMessage();
    this.message = messageResponse.message;

    if (messageResponse.registeredLogo) {
      const logo = await getLogo();
      const url = window.URL || window.webkitURL;
      const img = new Image();
      const parent = this;
      const src = url.createObjectURL(logo);
      img.onload = () => {
        parent.$set(parent, 'downerLogo', mainLogo);
        parent.$set(parent, 'upperLogo', src);
      };
      img.src = src;
    } else {
      this.$set(this, 'upperLogo', mainLogo);
    }
  },
};
</script>
