import axios from '@/apis/axios';
import store from '@/store';

export const getLogo = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    if (!urlToken) return reject(new Error('invalid token'));

    const response = await axios
      .get(`/contractors/agencies/logo`, {
        responseType: 'arraybuffer',
        headers: { 'access-token': 'dummy', 'url-token': urlToken },
      })
      .catch(error => {
        return reject(error);
      });
    resolve(new Blob([response.data]));
  });

export const getMessage = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    if (!urlToken) return reject(new Error('invalid token'));

    const response = await axios
      .get(`/contractors/agencies/message`, {
        headers: { 'access-token': 'dummy', 'url-token': urlToken },
      })
      .catch(error => {
        return reject(error);
      });
    resolve(response.data);
  });

export const getAgencies = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    if (!urlToken) return reject(new Error('invalid token'));

    const response = await axios
      .get(`/contractors/agencies`, {
        headers: { 'access-token': 'dummy', 'url-token': urlToken },
      })
      .catch(error => {
        return reject(error);
      });
      
    if (!response) return resolve([]);
    resolve(response.data);
  });
